$c-sidebar-anchor-spacing: .5 * $global-padding;
$main: 'main';
$main-space: 4.5rem;
$main-sidebar-width: 33.333%;
$main-animation-duration: .25s;

// Main
.main {
  position: relative;

  // Not active
  .#{$main}__sidebar {
    &-toggle {
      display: none;
    }
  }

  // Is active
  &.is-active {
    // Open sidebar
    &.open-sidebar {
      // Content
      .#{$main}__content {
        filter: blur(.125rem);

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
          background-color: transparentize($white, .25);
        }
      }

      // Sidebar
      .#{$main}__sidebar {
        z-index: 11;
        left: $main-sidebar-width;
        margin-left: 0;
        margin-right: 0;
        cursor: auto;
        box-shadow: 0 .0625rem .125rem transparentize($black, .75);

        &-content {
          &::before {
            display: none;
          }
        }

        &-toggle {
          left: -.25 * $main-space;

          &::before {
            transform: rotate(0deg);
          }
        }
      }
    }

    // Content
    .#{$main}__content {
      @include transition($main-animation-duration);
      padding-right: $main-space;
    }

    // Sidebar
    .#{$main}__sidebar {
      @include transition($main-animation-duration);
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 0;
      width: calc(100% - #{$main-sidebar-width});
      margin-left: -$main-space;
      background-color: $white;
      cursor: pointer;

      &:hover,
      &:focus {
        .#{$main}__sidebar {
          &-content {
            &::before {
              opacity: .75;
            }
          }
        }
      }

      &-content {
        position: relative;
        z-index: 1;

        &::before {
          @include transition($main-animation-duration);
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: $main-space;
          z-index: 1;
          background-image: linear-gradient(90deg, transparentize($white, .5), $white);
        }
      }

      &-toggle {
        @include transition(2 * $main-animation-duration);
        position: absolute;
        top: 0;
        left: .5 * $main-space;
        z-index: 2;
        transform: translateX(-50%);
        display: block;
        width: .5 * $main-space;
        height: $main-space;
        padding: $c-sidebar-anchor-spacing;
        line-height: inherit;
        background-color: $dark-gray;
        color: $white;

        &:hover,
        &:focus {
          background-color: tint($dark-gray, -10%);
        }

        &:active {
          background-color: tint($dark-gray, -20%);
        }

        &::before {
          @include transition(3 * $main-animation-duration);
          @extend .fi::before;
          content: $font-Flaticon-arrow-right;
          transform: rotate(-180deg);
        }
      }
    }
  }
}

// Sidebar
.c-sidebar {
  font-size: $small-font-size;

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.25rem;
    font-weight: $global-weight-bold;
    color: inherit;
  }

  // Navigation
  .b-navigation {
    @include gutter(margin-bottom);

    li,
    a {
      display: block;
    }

    li {
      &.active {
        a {
          background-color: tint($primary, 10%);
        }
      }
    }

    a {
      position: relative;
      margin-bottom: .125 * $global-margin;
      padding: $c-sidebar-anchor-spacing;
      background-color: $primary;
      color: $white;

      &,
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }

      &:hover,
      &:focus {
        background-color: tint($primary, 10%);
      }

      &::after {
        @extend .fi::before;
        content: $font-Flaticon-arrow-right;
        line-height: inherit;
        position: absolute;
        top: $c-sidebar-anchor-spacing;
        right: $c-sidebar-anchor-spacing;
      }
    }
  }

  // List
  .b-list {
    font-size: inherit;
  }

  // Reusables
  .b-reusables {
    .reusable {
      @include gutter(margin-bottom);
      overflow: hidden;

      &.has-background {
        padding: .75 * $global-padding;
        background-color: $light-gray;
      }

      > .row {
        @extend .row--nested;
      }

      .b-column {
        &:last-of-type {
          @include gutter(margin-bottom, 0);
        }
      }

      .b-gallery {
        // Auto height
        &--auto-height {
          .image {
            width: auto;
            max-height: 15rem;
          }
        }
      }

      &-title {
        font-size: 1rem;
        font-weight: $global-weight-normal;
        color: $dark-gray;
        border-bottom: .0625rem solid $medium-gray;
      }
    }
  }
}

.b-text {
  //@include gutter(margin-bottom);
  font-family: $font-family-secondary;

  &--center {
    h1, h2, h3, h4, h5, h6,
    p,
    .subheading,
    .button-wrapper {
      text-align: center;
    }
  }

  p {
  	font-family: $font-family-secondary;
    //margin-bottom: 1em;
  }

  ul {
    @include list;
    color: $dark-primary;
  }

  a, p a {
    &[href] {
    	font-family: $body-font-family;
    	font-size: 1rem;
    	color: $primary;
    	text-decoration: underline;
    	&:hover {
    		text-decoration: none;
    	}

      &::before,
      &::after {
        text-decoration: none;
      }
    }

    &[href$='.pdf'] {
      &::before {
        @extend .fi::before;
        content: $font-Flaticon-pdf;
        display: inline-block;
        margin-right: .25em;
      }
    }



    &[href^='mailto:'] {
      &::before {
        @extend .fi::before;
        content: $font-Flaticon-email;
        display: inline-block;
        margin-right: .125em;
        font-size: 1.25em;
        vertical-align: middle;
        color: $dark-gray;
      }
    }
  }
}

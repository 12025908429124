.b-button {
	text-align: center;
}

button, a.button, .todo-fix-ugly-buttons {
	color: $white;
	background-color: $primary;
	font-size: 1rem;
	font-weight: $global-weight-semi-bold;
	// height: 3.375rem;
  height: 3.25rem;
	padding: 14px 35px 13px;
	border-radius: 8px;
	margin-top: 1rem;
	&:hover {
		color: $primary;
		background-color: transparent;
		border-color: $primary;
	}
}

	
.b-button {
  // Button
  .button {
    &.icon-check,
    &.icon-email,
    &.icon-phone {
      &::before {
        @extend .fi::before;
        display: inline-block;
        margin-right: .75rem;
        font-size: 2rem;
        vertical-align: inherit;
      }
    }

    &.icon-check::before {
      content: $font-Flaticon-check;
    }

    &.icon-email::before {
      content: $font-Flaticon-email;
    }

    &.icon-phone::before {
      content: $font-Flaticon-phone;
    }
  }
}

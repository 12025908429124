$c-slider-dots-size: .75rem;

@mixin c-slider-buttons($direction: false, $font-size: 2rem) {
  @if $direction == false {
    @include disable-mouse-outline;
    @include transition;
    width: $font-size + (2 * $global-padding);
    height: $font-size + (2 * $global-padding);
    font-size: $font-size;
    padding: $global-padding;
    border: 0;
    background-color: $primary;
    color: $white;
    border-radius: 50%;
    text-shadow: none;

    &:hover,
    &:focus {
      background-color: tint($primary, 20%);
    }

    &:active {
      background-color: tint($primary, -20%);
    }

    &::before {
      @extend .fi::before;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  } @else if $direction == left {
    &::before {
      content: $font-Flaticon-arrow-left;
    }
  } @else if $direction == right {
    &::before {
      content: $font-Flaticon-arrow-right;
    }
  }
}

.c-slider {
  position: relative;
  overflow: hidden;
  z-index: 0;

  @at-root {
    .no-js & img {
      width: 100%;
    }
  }

  .slick {
    &-slide {
      outline: 0;
    }

    &-prev,
    &-next {
      @include c-slider-buttons($font-size: 3rem);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      text-shadow: 0 0 .125rem transparentize($black, .5);
      z-index: 1;

      &:hover,
      &:active {
        text-shadow: none;
      }

      &:hover,
      &:focus {
        background-color: $primary;
      }

      &:active {
        background-color: tint($primary, -10%);
      }
    }

    &-prev {
      @include c-slider-buttons(left);
      left: $global-margin;
    }

    &-next {
      @include c-slider-buttons(right);
      right: $global-margin;
    }

    &-dots {
      position: absolute;
      bottom: $global-margin;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      list-style-type: none;

      li {
        @include transition;
        display: inline-block;
        margin-right: .25 * $global-margin;
        width: $c-slider-dots-size;
        height: $c-slider-dots-size;
        border-radius: 50%;
        border: .0625rem solid $white;

        button {
          display: block;
          width: 100%;
          height: 100%;
          color: transparent;
          font-size: 0;
          line-height: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &.slick-active {
          background-color: $white;
        }
      }
    }
  }

  // TODO: Remove for next project!
  .slick {
    &-prev,
    &-next {
      @include c-slider-buttons($font-size: 1.5rem);
      top: auto;
      left: $global-margin;
      bottom: $global-margin;
      transform: none;
      width: $c-slider-button-width;
      height: $c-slider-button-width;
      border-radius: 0;
      background-color: transparentize($white, .5);
      color: $black;
      text-shadow: none;

      &:hover,
      &:focus {
        background-color: transparentize($white, .25);
      }

      &:active {
        background-color: transparentize($white, .125);
      }
    }

    &-next {
      right: auto;
      margin-left: $c-slider-button-width + 0.5;
    }

    &-dots {
      display: none !important;
    }
  }
}

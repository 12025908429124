$c-zellenkur: 'c-zellenkur';
$c-zellenkur-width: 1.5rem;
$c-zellenkur-height: $c-zellenkur-width;
$c-zellenkur-duration: .75s;

.#{$c-zellenkur} {
  @include transition($c-zellenkur-duration);
  display: block;
  width: $c-zellenkur-width;
  height: $c-zellenkur-height;
  overflow: hidden;

  &:hover,
  &:focus {
    .#{$c-zellenkur} {
      &__svg {
        transform: rotate(180deg);
      }
    }
  }

  &:active {
    .#{$c-zellenkur} {
      &__svg {
        @include transition(.25s);
        fill: $primary;
      }
    }
  }

  // SVG
  &__svg {
    @include transition($c-zellenkur-duration);
    display: block;
    fill: $black;
  }
}

$b-list: 'b-list';

.#{$b-list} {
  @include gutter(margin-bottom);
  font-size: 1.5rem;
  color: $dark-primary;

  &-main {
    margin-left: $list-side-margin;
    list-style-type: none;
  }

  &-item {
    position: relative;
    display: block;
    margin-bottom: .5em;
    hyphens: auto;
  }

  .value {
    position: relative;
    display: block;
    margin-left: 2.5em;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    display: block;
    border: .0625rem solid $primary;
    border-radius: 50%;
    color: $primary;
    text-align: center;

    &::before {
      @extend .fi::before;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--icon-check {
    .icon::before {
      content: $font-Flaticon-check;
    }
  }

  &--icon-email {
    .icon::before {
      content: $font-Flaticon-email;
    }
  }

  &--icon-phone {
    .icon::before {
      content: $font-Flaticon-phone;
    }
  }

  &--icon-none {
    .#{$b-list}-item {
      &::before {
        @include list-icon;
      }
    }

    .icon {
      display: none;

      + .value {
        margin-left: .25em;
      }
    }
  }
}

div.t-store {
	.overlay {
		background-color: transparentize($black, .3);
		font-family: $body-font-family;
		padding: 3rem 1.5rem 2rem 1.5rem;

		@include breakpoint(large) {
			position: absolute;
			left: 14.5px;
			bottom: 0rem;
			width: calc(50% - 29px);
		}
		.b-image {
			float: left;
			margin-right: 1rem;
			width: 48px;
		}
		h1 {
			color: $white;
			text-transform: none;
			text-align: left;
			float: left;
		}
		p, span, a {
			color: $white;
			font-size: 0.875rem;
			margin: 0;
			line-height: 18px;
		}
		p, span {
			font-weight: bold;
		}

    .address-storehours {
      display: flex;
      width: 100%;
      padding-top: 1rem;
    }
		.address {
			flex: 2;
      margin-right: 1rem;

			a.calc-route {
				text-decoration: underline;
				margin-left: 1.5rem;
        display: inline-flex;
        padding-top: 1rem;
        padding-bottom: 1rem;
        align-items: center;


				&::before {
					position: absolute;
				    content: url(/foundation/dist/assets/img/location-white.svg);
				    margin: 0.125rem 0 0 -1.5rem;
				}
			}
		}
		.storehours {
      flex: 3;
		}

    .c-hours {
      margin-bottom: 0;
      font-size: 0.875rem;
      color: $white;
    }

    .c-hours-update {
      margin-top: 0;
    }
	}
}

#address-hours-mobile {
	display: block;
	@include breakpoint(large) {
		display: none;
	}
}


#address-hours-desktop {
	display: none;
	@include breakpoint(large) {
		display: block;
	}
}

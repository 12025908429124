$b-gallery: 'b-gallery';
$b-gallery-height-default: $group-height;
$b-gallery-height-small: $b-gallery-height-default - 7rem;
$b-gallery-height-large: $b-gallery-height-default + 7rem;
$b-gallery-video-max-width: $global-width;

.#{$b-gallery} {
  @include gutter(margin-bottom);
  position: relative;
  overflow: hidden;

  .vide,
  .iframe,
  .focuspoint,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
  }

  .vide,
  .iframe,
  .slick-slider {
    height: $b-gallery-height-small;

    @include breakpoint(medium) {
      height: $b-gallery-height-default;
    }
  }

  &--full-width {
    @include full-width;
  }

  &--height {
    &-small {
      .vide,
      .iframe,
      .slick-slider {
        height: $b-gallery-height-small;

        @include breakpoint(medium) {
          height: $b-gallery-height-small;
        }
      }
    }

    &-large {
      .vide,
      .iframe,
      .slick-slider {
        height: $b-gallery-height-small;

        @include breakpoint(medium) {
          height: $b-gallery-height-large;
        }
      }
    }

  }

  .vide {
    video {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .iframe {
    display: block;
    width: 100%;
    border: 0;
  }

  .disabled-focuspoint {
    .image {
      top: auto !important;
      left: auto !important;
      position: static;
    }
  }

  // Overlay
  &--overlay {
    .open-overlay {
      @include transition;
      @include fix-buttons;
      position: absolute;
      top: $global-margin;
      right: $global-margin;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      padding: $global-padding;
      // background-color: transparentize($black, .25);
      text-shadow: -.0625rem .0625rem 0 transparentize($black, .5);
      color: $white;

      &::before {
        @extend .fi::before;
        content: $font-Flaticon-zoom-in;
      }

      &:hover,
      &:focus {
        background-color: $primary;
        text-shadow: none;
      }

      &:active {
        background-color: $light-primary;
      }
    }

    &:hover,
    &:focus {
      .open-overlay {
        visibility: visible;
        opacity: 1;
      }
    }

    .image,
    .vide {
      cursor: zoom-in;
    }
  }

  // Link
  &--link {
    display: block;

    .open-overlay {
      display: none;
    }

    .image,
    .vide {
      cursor: auto;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }

  // Overlay text
  .overlay-text {
    @include transition;
    @include gutter(padding, 2);

    .button-wrapper a {
      @extend .todo-fix-ugly-buttons;
      text-decoration: none;
    }

    @include breakpoint(medium) {
      @include dark-background;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
    }
  }

  // Whole media and auto height
  &--whole-media,
  &--auto-height {
    .vide-wrapper,
    .iframe-wrapper {
      @include aspect-ratio(16, 9);
    }
  }

  // Whole media
  &--whole-media {
    .image {
      position: relative;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      width: 100%;
      min-width: 100%;
      height: auto;
      min-height: 0;

      &--vertical {
        width: auto;
        min-width: 0;
        height: 100%;
        min-height: 100%;
      }
    }
  }

  // Auto height
  &--auto-height {
    .focuspoint,
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: auto;
    }

    .image {
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      height: auto;
    }

    [data-slick-count='1'] .slick-list {
      height: auto !important;
    }
  }

  // Spacing
  &.spacing {
    &-1 {
      .slick-slide {
        padding-left: $global-padding;
        padding-right: $global-padding;
      }
    }

    &-2 {
      .slick-slide {
        padding-left: 2 * $global-padding;
        padding-right: 2 * $global-padding;
      }
    }

    &-3 {
      .slick-slide {
        padding-left: 3 * $global-padding;
        padding-right: 3 * $global-padding;
      }
    }
  }

  // Overlay
  &-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1111;
    background-color: transparentize($black, .1);
    color: $white;

    &__close {
      @include transition;
      @include fix-buttons;
      position: absolute;
      top: $global-margin;
      right: $global-margin;
      z-index: 1;
      padding: 1.5 * $global-padding;
      font-size: 1.5rem;
      background-color: transparentize($black, .5);

      &:hover,
      &:focus {
        background-color: $primary;
        color: $white;
      }

      &:active {
        background-color: $light-primary;
        color: $white;
      }

      &::before {
        @extend .fi::before;
        content: $font-Flaticon-close;
      }
    }

    .b-gallery {
      @extend .b-gallery--whole-media;
      margin: 0 !important;
      padding: $global-padding !important;
      z-index: 0;

      &,
      .vide,
      .iframe,
      .focuspoint,
      .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide {
        height: 100% !important;
      }

      .vide-wrapper,
      .iframe-wrapper {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: $b-gallery-video-max-width;
        height: auto !important;
        max-height: 100%;
      }

      .open-overlay {
        display: none;
      }
    }
  }

  // Is overlay
  @at-root .body--is-overlay {
    .#{$b-gallery} {
      &-overlay {
        display: block;
      }

      .image,
      .vide {
        cursor: auto;
      }
    }

    > *:not(.#{$b-gallery}-overlay) {
      filter: blur(.25rem);
    }
  }
}

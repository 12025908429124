.c-languages {
  text-transform: uppercase;

  ul {
    margin: 0;
    list-style-type: none;
  }

  li,
  a {
    display: inline-block;
  }

  li {
    &.active {
      a {
        text-decoration: underline;
      }
    }

    &::after {
      content: '|';
      display: inline-block;
      padding-left: .25rem;
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }

  a {
    color: inherit;
  }
}

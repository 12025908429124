.c-form {
  legend {
    display: none;
  }

  .content {
    margin-bottom: 3 * $global-margin;
    text-align: center;
  }

  .fields {
    margin-bottom: 2 * $global-margin;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    @include gutter(margin-left, -.5);
    @include gutter(margin-right, -.5);

    fieldset {
      @include gutter(margin-left, .5);
      @include gutter(margin-right, .5);
      flex: 1;
    }
  }

  input,
  textarea,
  select {
    display: block;
    max-width: 100%;
  }

  input {
    min-width: 5rem;
  }

  textarea {
    resize: vertical;
    min-height: 8rem;
  }

  .button {
    display: block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  [type='file'] {
    display: block;
    width: auto;
    padding: .5 * $form-spacing;
    background-color: $primary;
    color: $white;
    line-height: inherit;
  }
}

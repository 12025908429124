/**
.row {
	outline: 1px solid red;

	.row {
		outline-color: green;

		.row {
			outline-color: blue;
		}
	}
}
/**/

div#debug-gutter {
	display: none;
}

div#debug-gutter {
	position: fixed;
	left: 50%;
	margin-left: -600px;
	z-index: -1000;
	div.row 
	{
		width:1200px;
		max-width: none;
		padding: 0;
		div.b-column
		{
			border-right: 1px solid blue;
			height: 100vh;
			background-color: #CCCCCC;
			> div {
				 background-color: white;
				 height: 100%;
			}
		}
	}
}


div.t-store {
  &.store--header {
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

	&.store--map {
    position: relative;
    z-index: 0;
		background-color: #F1E2D2;

		.column-content {
			.iframe-google-map {
				height: 20rem;
				width: 100%;
				padding-bottom: 0;
				margin-bottom: -6px;
			}
			.map-description {
        margin: 1.5rem 2.5rem;

				.directions {
					font-family: $font-family-secondary;
					line-height: 1.375rem;
				}

        .c-hours-update {
          border: 0;
          padding: 0;
          margin: 0;
        }
			}
		}
		@include breakpoint(medium) {
			.map-column .column-content, .map-column .iframe-google-map {
        position: absolute;
        width: 100%;
        height: 100%;
			}
		}
	}
}

$b-image: '.b-image';

.b-image {
  &-content {
    max-width: 100%;
  }

  &.align {
    &-left {
      #{$b-image}-content {
        margin-left: 0;
        margin-right: auto;
      }
    }

    &-center {
      #{$b-image}-content {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-right {
      #{$b-image}-content {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}

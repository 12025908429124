    /*
    Flaticon icon font: Flaticon
    Creation date: 06/12/2016 21:49
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/Flaticon.eot");
  src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi::before {
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-arrow-bottom::before { content: "\f100"; }
.flaticon-arrow-left::before { content: "\f101"; }
.flaticon-arrow-long-bottom::before { content: "\f102"; }
.flaticon-arrow-long-left::before { content: "\f103"; }
.flaticon-arrow-long-right::before { content: "\f104"; }
.flaticon-arrow-long-top::before { content: "\f105"; }
.flaticon-arrow-right::before { content: "\f106"; }
.flaticon-arrow-top::before { content: "\f107"; }
.flaticon-check::before { content: "\f108"; }
.flaticon-close::before { content: "\f109"; }
.flaticon-email::before { content: "\f10a"; }
.flaticon-fullscreen::before { content: "\f10b"; }
.flaticon-menu::before { content: "\f10c"; }
.flaticon-pdf::before { content: "\f10d"; }
.flaticon-phone::before { content: "\f10e"; }
.flaticon-zoom-in::before { content: "\f10f"; }
.flaticon-zoom-out::before { content: "\f110"; }

    $font-Flaticon-arrow-bottom: "\f100";
    $font-Flaticon-arrow-left: "\f101";
    $font-Flaticon-arrow-long-bottom: "\f102";
    $font-Flaticon-arrow-long-left: "\f103";
    $font-Flaticon-arrow-long-right: "\f104";
    $font-Flaticon-arrow-long-top: "\f105";
    $font-Flaticon-arrow-right: "\f106";
    $font-Flaticon-arrow-top: "\f107";
    $font-Flaticon-check: "\f108";
    $font-Flaticon-close: "\f109";
    $font-Flaticon-email: "\f10a";
    $font-Flaticon-fullscreen: "\f10b";
    $font-Flaticon-menu: "\f10c";
    $font-Flaticon-pdf: "\f10d";
    $font-Flaticon-phone: "\f10e";
    $font-Flaticon-zoom-in: "\f10f";
    $font-Flaticon-zoom-out: "\f110";

$b-icon-width: 1.5 * $global-margin;

.b-icon {
  @include gutter(margin-bottom);
  font-size: $content-font-size;

  & + & {
    @include gutter(margin-top, -.5);
  }

  &--horizontal {
    .c-icon {
      display: flex;
      margin-left: 0;
      margin-right: 0;
      color: inherit;

      &__image {
        width: $b-icon-width;
        padding-bottom: $b-icon-width;
        margin-left: 0;
        margin-right: .75* $global-margin;
        border: 0;
        border-radius: 0;

        &__layer1 {
          width: 100%;
        }
      }

      &__text {
        margin-top: 0;
        text-align: left;
        hyphens: auto;
      }
    }
  }
}

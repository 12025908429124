.c-hours-update {
  display: inline-block;
  margin-top: .75 * $global-margin;
  margin-bottom: .75 * $global-margin;
  padding: (.5 * $global-margin) (.75 * $global-margin);
  border: 2px solid $primary;

  .c-hours-update-date {
    font-weight: $global-weight-bold;
  }
}

$c-pagination-icon-space: .5rem;

.c-pagination {
  @include gutter(margin-bottom);
  display: block;
  list-style-type: none;

  &--center {
    text-align: center;
  }

  &__item {
    &--previous {
      a::before {
        @extend .fi::before;
        content: $font-Flaticon-arrow-left;
        display: inline-block;
        padding-right: $c-pagination-icon-space;
      }
    }

    &--next {
      a::after {
        @extend .fi::before;
        content: $font-Flaticon-arrow-right;
        display: inline-block;
        padding-left: $c-pagination-icon-space;
      }
    }
  }

  li,
  a {
    display: inline-block;
  }

  li {
    @include gutter(margin-bottom);
  }

  a {
    @include gutter(margin-bottom, 0);
  }
}

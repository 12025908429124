.c-campaign {
  &__text {
    order: 2;
    text-align: center;

    @include breakpoint(medium) {
      &-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-top: 2 * $global-padding;
        padding-bottom: 2 * $global-padding;
      }
    }
  }

  &__media {
    order: 1;
    min-height: $group-height - 20;

    @include breakpoint(medium) {
      min-height: $group-height - 10;

      .background-image {
        display: none;
      }
    }
  }

  .campaign {
    position: relative;
    margin-bottom: 2 * $global-margin !important;

    &--image-position {
      &-0 {
        .c-campaign__media .column-content {
          position: absolute;
          top: 0;
        }
      }

      &-50 {
        .c-campaign__media .column-content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &-100 {
        .c-campaign__media .column-content {
          position: absolute;
          bottom: 0;
        }
      }
    }

    &.text-first {
      @include breakpoint(medium) {
        .c-campaign__text {
          order: 1;
        }

        .c-campaign__media {
          order: 2;
        }
      }
    }

    .campaign-image {
      position: relative;
      z-index: 1;
    }

    > .background-image {
      display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }
  }

  .background-image {
    @include full-width;
    position: absolute !important;
    height: 100%;
    z-index: 0;

    .focuspoint {
      width: 100%;
      height: 100%;
    }
  }

  .description {
    font-family: $font-family-secondary;
    font-size: 0.875rem;

    @include breakpoint(medium) {
      font-size: 1rem;
    }
  }

  .period {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    position: absolute;
    top: 2rem;
    left: 2rem;
    padding: 1rem;
    z-index: 1;
    text-align: center;
    background-color: transparentize($primary, 0.3);
    color: $white;

    &-start {
      font-weight: bold;
      font-size: 0.875rem;
      margin-bottom: 2px;
    }

    &-dates {
      font-weight: bold;
      font-family: $font-family-secondary;
      font-size: 1.25rem;
    }
  }

  .cafe-b {
    width: 7rem;
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;
  }
}

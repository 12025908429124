.b-jobs-teaser {
  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding-right: .5 * $global-padding;

    &::after {
      @extend .fi::before;
      content: $font-Flaticon-arrow-right;
      font-size: 1.5rem;
    }
  }
}

$c-header-background-color: $white;
$c-header-color: $black;
$c-header-logo-width: 5rem;
$c-header-logo-padding: .75 * $global-padding;
$c-header-x-space: $global-padding;
$c-header-y-space: 1.25 * $global-padding;
$c-header-mobile-links-padding: (.5 * $c-header-y-space) $c-header-x-space;
$c-header-desktop-links-padding: $c-header-y-space $c-header-x-space;
$c-header-transition: .5s;
$c-header-transition-fast: .25s;

@mixin c-header-buttons($padding: $c-header-links-padding) {
  @include transition;
  @include fix-buttons;
  padding: $padding;
  background-color: transparent;
  color: $primary;
  line-height: inherit;

  &:hover,
  &:focus {
    background-color: $primary;
    color: $white;
  }

  &:active {
    background-color: tint($primary, -10%);
  }

  &::before,
  &::after {
    @include transition($c-header-transition-fast);
  }
}

.c-header {
  // @include transition($c-header-transition);
  // @include gutter(margin-bottom, 3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  background-color: $c-header-background-color;
  color: $c-header-color;
  border-bottom: 1px solid $light-gray;

  @at-root .header-transparent {
    @include breakpoint(medium) {
      .c-header,
      .c-header-desktop {
        background-color: transparent;
      }

      .fake-header {
        display: none;
      }
    }
  }

  &.headroom--not-top {
    background-color: $white;

    .c-header-mobile {
      .c-header-logo {
        width: $c-header-logo-width - 1;
        min-width: $c-header-logo-width - 1;
      }
    }

    .c-header-desktop {
      .c-header-logo {
        width: $c-header-logo-width + 5;
        min-width: $c-header-logo-width + 5;
        transform: translateY(2.25 * $global-margin);
        margin-top: 0;
        margin-bottom: 0;

        .logo-text {
          height: 0;
          opacity: 0;
          visibility: hidden;
        }
      }

      .c-header-navigation {
        transform: translateY(0);
      }
    }

    .c-header-stores-mobile {
      display: none !important;
    }
  }

  // &.headroom--unpinned {
  //   @include breakpoint(medium) {
  //     transform: translateY(-100%);
  //   }
  // }

  .c-header-logo {
    @include transition($c-header-transition);
    width: $c-header-logo-width;
    min-width: $c-header-logo-width;
    // margin-right: 2 * $global-margin;

    .logo-image,
    .logo-text {
      position: relative;
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .logo-image {
      width: 50%;
      margin-bottom: .375 * $global-margin;
      z-index: 1;
    }

    .logo-text {
      @include transition($c-header-transition);
      opacity: 1;
      visibility: visible;
      z-index: 0;
    }

    .image {
      @include transition($c-header-transition);
      position: absolute;
      top: 50%;
      left: $global-margin;
      transform: translateY(-40%);
      width: inherit;
      height: auto;
      // filter: drop-shadow(.125rem .125rem .125rem transparentize($black, .25));
    }
  }

  .c-header-label {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: .5 * $global-margin;
    margin-right: .5 * $global-margin;
    line-height: 1.25;
    font-weight: $global-weight-bold;

    a {
      color: inherit;
      text-decoration: none;
    }

    * {
      margin-bottom: 0;
      line-height: inherit;
    }
  }

  .c-header-navigation {
    @include transition($c-header-transition);
    font-weight: $global-weight-bold;

    ul {
      list-style-type: none;
    }

    a {
      @include transition($c-header-transition-fast);
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: transparent;
        // text-decoration: underline;
      }

      &:active {
        background-color: transparent;
      }
    }

    &__links {
      margin: 0;
    }

    .toggle-children {
      @include c-header-buttons($c-header-mobile-links-padding);
      background-color: tint($primary, 10%);
      color: inherit;
      position: absolute;
      top: 0;
      right: 0;

      &:hover,
      &:focus {
        background-color: tint($primary, 20%);
      }

      &:active {
        background-color: tint($primary, 30%);
      }

      &::before {
        @extend .fi::before;
        content: $font-Flaticon-arrow-right;
      }

      &.open {
        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }

  // Mobile
  &-mobile {
    .c-header-logo {
      position: absolute;
      left: 50%;
      top: $global-margin;
      transform: translateX(-50%);

      .logo-image {
        width: 100%;
      }

      .logo-text {
        display: none;
      }
    }

    .c-header-navigation {
      color: $white;

      li,
      a {
        display: block;
      }

      li {
        position: relative;
        border-bottom: 1px solid tint($primary, 10%);

        &:last-of-type {
          border-bottom: 0;
        }
      }

      a {
        padding: $c-header-mobile-links-padding;

        &:hover,
        &:focus {
          span {
            text-decoration: underline;
          }
        }
      }

      &__links {
        ul {
          display: none;

          &.open {
            display: block;
          }
        }
      }
    }

    .c-header-toggle-navigation {
      @include c-header-buttons($c-header-mobile-links-padding);
      padding-top: $c-header-y-space;
      padding-bottom: $c-header-y-space;
      display: flex;
      color: $dark-gray;

      &::before {
        @extend .fi::before;
        content: $font-Flaticon-close;
        transform: rotate(45deg);
      }
    }

    &__top,
    &__bottom {
      position: relative;
    }

    &__top {
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      background-color: $c-header-background-color;
      z-index: 1;
    }

    &__bottom {
      display: none;
      background-color: $primary;
      z-index: 0;
    }
  }

  // Desktop
  &-desktop {
    @include inside-gutter(padding-left);
    @include inside-gutter(padding-right);
    display: none !important;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
    background-color: $c-header-background-color;

    // @include breakpoint($global-width) {
    //   @include inside-gutter(padding-left, 0);
    //   @include inside-gutter(padding-right, 0);
    // }

    .c-header-navigation {
      @extend [data-more-nav]; // Fix
      align-items: flex-end;
      transform: translateY(-.75 * $global-margin);

      &--left {
        justify-content: flex-end;
      }

      &__links,
      li,
      a {
        display: flex;
      }

      &__links > li {
        margin-left: .5 * $global-margin;
        margin-right: .5 * $global-margin;

        @include breakpoint(large) {
          margin-left: $global-margin;
          margin-right:$global-margin;
        }

        > a {
          &::after {
            @include transition($c-header-transition-fast);
            content: '';
            position: absolute;
            bottom: .66 * $c-header-y-space;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 0;
            background-color: transparent;
          }
        }
      }

      li {
        > a:hover,
        > a:focus,
        &.active > a {
          color: $primary;

          &::after {
            width: 33%;
            background-color: $primary;
          }
        }

        &.more > a {
          padding-left: 2 * $global-padding;
          padding-right: 2 * $global-padding;
          font-size: 1.5rem;
          line-height: 1;
          font-weight: $global-weight-bold;

          &:hover,
          &:focus {
            background-color: $primary;
            color: $white;
          }
        }

        &.dropdownOpen {
          ul {
            min-width: 10rem;
            max-width: 15rem;
            background-color: transparentize($white, .05);
            box-shadow: 0 0 0 1px $primary;

            li,
            a {
              display: block;
            }

            a {
              padding: .75 * $global-padding;
              color: $black;

              &:hover,
              &:focus {
                color: $primary;
              }
            }

            span {
              display: inline;
            }

            // a:hover,
            // a:focus,
            li.active > a {
              color: $primary;

              &::after {
                content: none;
              }
            }
          }
        }
      }

      a,
      span {
        white-space: normal;
        overflow: hidden;
      }

      a {
        align-items: center;
        padding: $c-header-desktop-links-padding;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }

    .c-header-logo {
      margin: (.75 * $global-margin) (2 * $global-margin);
      min-width: $c-header-logo-width + 4;
      width: $c-header-logo-width + 4;

      .image {
        left: 0;
      }
    }

    .c-header-identity {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
    }
  }

  // Stores
  &-stores {
    &-mobile,
    &-desktop {
      display: none !important;
      background-color: $white;

      .stores {
        font-weight: $global-weight-bold;
        line-height: 1.25;

        .store {
          color: inherit;
          text-decoration: none;

          &:hover,
          &:focus {
            // color: $primary;

            .title {
              text-decoration: underline;
            }
          }
        }

        .title,
        .distance {
          font: inherit;
          line-height: inherit;
          color: inherit;
        }

        .title {
          margin-bottom: 0;
          margin-right: .5 * $global-margin;
        }

        .distance {
          color: $primary;
          white-space: nowrap;
        }

        .store:nth-of-type(n+3) {
          display: none;
        }
      }
    }

    &-mobile {
      .stores {
        border-bottom: 1px solid $light-gray;
        padding: (.5 * $global-padding) $global-padding;

        .store {
          display: block;
          padding-top: .25 * $global-padding;
          padding-bottom: .25 * $global-padding;

          &::before {
            content: url(/foundation/dist/assets/img/location-red.svg);
            position: absolute;
          }
        }

        .text {
          display: flex;
          margin-left: 1.5 * $global-margin;
        }

        .title {
          @include ellipsis;
        }

        .image {
          display: none;
        }
      }
    }

    &-desktop {
      @include transition($c-header-transition);
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 0;
      transform: translateY(-100%);
      visibility: hidden;
      padding: 3 * $global-padding;
      padding-right: 5 * $global-padding;
      overflow: hidden;
      flex-wrap: nowrap;
      border-top: 1px solid $light-gray;

      &-wrapper {
        @include gutter(margin-left);
        @include gutter(margin-right);
        position: relative;

        @include breakpoint($global-width) {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-toggle {
        @include fix-buttons;
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem !important;
        height: 3rem !important;
        padding: 0;
        background-color: $primary;
        color: $white;

        &:hover,
        &:focus {
          background-color: tint($primary, 20%);
          color: $white;
        }

        &::before {
          @include transition($c-header-transition);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: url(/foundation/dist/assets/img/location-white.svg);
        }
      }

      &-title {
        margin-right: 2 * $global-margin;
        font: inherit;
        font-weight: $global-weight-semi-bold;
        line-height: 1.25;
        color: $primary;
        text-transform: uppercase;
        flex: 1;
        display: flex;
        flex-wrap: nowrap;

        &::before {
          content: url(/foundation/dist/assets/img/location-red.svg);
          display: inline-block;
          margin-right: .5 * $global-margin;
          vertical-align: top;
        }
      }

      .stores {
        flex: 3;

        &__sorted,
        &__unsorted {
          display: flex;
          flex-wrap: nowrap;
        }

        .store {
          flex: 1;

          &:first-child {
            margin-right: 2 * $global-margin;

            @include breakpoint($global-width) {
              margin-right: 4 * $global-margin;
            }
          }
        }

        .text {
          display: flex;
          flex-wrap: nowrap;
        }

        .image {
          margin-bottom: .75 * $global-margin;
        }

        .focuspoint {
          height: 6rem;
        }
      }
    }
  }

  // Open
  &.open {
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparentize($black, .05);

    .c-header-mobile {
      &__top {
        background-color: $white;
      }

      .c-header-logo {
        width: $c-header-logo-width - 1.5;
        min-width: $c-header-logo-width - 1.5;
        top: .75 * $global-margin;

        .logo-image {
          margin-bottom: 0;
        }

        .image {
          transform: translateY(-45%);
        }
      }

      .c-header-toggle-navigation {
        &::before {
          // content: $font-Flaticon-close;
          transform: rotate(180deg);
        }
      }

      &__bottom {
        display: block;
      }
    }

    .c-header-stores-mobile {
      display: none !important;
    }
  }

  @at-root .stores-init {
    .c-header-stores-mobile {
      display: block !important;
    }

    @include breakpoint(medium) {
      .c-header-stores-mobile {
        display: none !important;
      }

      .c-header-stores-desktop-toggle {
        display: block;
      }
    }
  }

  @at-root .stores-visible {
    .c-header-stores-desktop {
      transform: translateY(0);
      visibility: visible;
    }

    .c-header-stores-desktop-toggle {
      &::before {
        @extend .fi::before;
        content: $font-Flaticon-close;
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  @include breakpoint(medium) {
    &-mobile {
      display: none !important;
    }

    &-desktop {
      display: flex !important;
    }

    &-stores-desktop {
      display: flex !important;
    }
  }
}

$c-icon: 'c-icon';
$c-icon-image-width: 75%;

.#{$c-icon} {
  @include gutter(margin-bottom);
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;

  @include breakpoint(medium) {
    max-width: none;
  }

  &,
  &:hover,
  &:focus {
    color: $dark-gray;
  }

  // Contain icon
  &--contain-icon {
    .#{$c-icon} {
      &__image {
        &__layer1 {
          width: 100%;
        }
      }
    }
  }

  // Has background
  &--has-background {
    .#{$c-icon} {
      &__image {
        border: 0;
        background-color: $primary;
      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: $c-icon-image-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: $c-icon-image-width;
    border-radius: 50%;
    border: .0625rem solid $dark-gray;

    &__layer1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      width: 75%;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    margin-top: $global-margin;
    text-align: center;
    color: inherit;
  }
}

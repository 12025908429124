.b-call-to-action {
  padding-top: 0;
  
  font-size: 1rem;
  color: $white;
  text-align: center;

  a, h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }


  .button {
    font-size: 1rem;
    border: 0;
    background-color: $primary;
    color: $white;
    cursor: auto;
    text-decoration: none;
	margin-top: 0;
	
    &[href] {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
      }

      &:active {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
      }
    }
  }

  .b-button {
    display: inline-block;
  }
}

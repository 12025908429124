.c-nearBy {
	margin-bottom: -2.5rem;
	.description {
		font-family: $font-family-secondary;	
	}
	.image .focuspoint {
		height: 18rem;	
	}
	.caption {
		text-align: center;
		margin-top: 0.625rem;
		p {
			font-size: 1rem;
			font-weight: $global-weight-semi-bold;
		}	
		a[href] {
			font-size: 1rem;
			font-weight: $global-weight-semi-bold;
			color: $black;
			&:hover {
				text-decoration: none;
			}
		}	
	}
	div.button-stores {
		text-align: center;
	}
}

@charset 'utf-8';

@function tint($color, $percentage) {
  $with: #ffffff;

  @if strip-unit($percentage) < 0 {
    $percentage: -1 * $percentage;
    $with: #000000;
  }

  @return mix($with, $color, $percentage);
}

@function inside-gutter-value($breakpoint, $without-column: false) {
  $gutter: map-get($inside-gutter, $breakpoint);

  @if $without-column {
    $column-gutter: map-get($grid-column-gutter, $breakpoint);
    $gutter: $gutter - (.5 * $column-gutter);
  }

  @return $gutter;
}

@mixin full-width() {
  width: 100vw !important;
  position: relative !important;
  left: 50% !important;
  right: 50% !important;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
}

@mixin dark-background() {
  color: $white;

  h1, h2, h3, h4, h5, h6,
  a, p,
  .b-list {
    color: inherit;
  }

  .button-wrapper a:hover,
  .button-wrapper a:focus {
    border-color: $white;
    background-color: $white;
    color: $primary;
  }
}

@mixin transition($duration: .125s) {
  transition: all $duration ease;
}

@mixin gutter($property, $value: 1, $inside: false, $important: false) {
  $gutter: $grid-column-gutter;

  @if $inside {
    $gutter: $inside-gutter;
  }

  @each $gutter-key, $gutter-value in $gutter {
    $p: $property;
    $v: 0;

    @include breakpoint($gutter-key) {
      @if not unitless($value) {
        $v: $value;
      } @else if strip-unit($value * $gutter-value) != 0 {
        $v: $value * $gutter-value;
      }

      @if $important {
        #{$p}: $v !important;
      } @else {
        #{$p}: $v;
      }
    }
  }
}

@mixin inside-gutter($property, $value: 1, $inside: true, $important: false) {
  @include gutter($property, $value, $inside, $important);
}

@mixin aspect-ratio($width, $height, $child: ':first-of-type') {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > #{$child} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin list-icon($content: '●', $indent: $list-side-margin) {
  content: $content;
  position: absolute;
  top: 0;
  left: -$indent;
  font-size: 1.5em;
  line-height: 1;
  color: $secondary;
}

@mixin list($content: '●', $indent: $list-side-margin, $spacing: .5 * $global-margin) {
  list-style-type: none;
  margin-left: $indent;

  li {
    position: relative;
    margin-bottom: $spacing;

    &::before {
      @include list-icon($content, $indent);
    }

    ul {
      margin-top: $spacing;
    }
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fix-buttons() {
  margin: 0 !important;
  border-radius: 0 !important;
  height: auto !important;
}

@mixin gradient-linear($color-from, $color-to, $to: 'bottom') {
	background: mix($color-from, $color-to, 50%); /* For browsers that do not support gradients */
	background: -webkit-linear-gradient($to, $color-to, $color-from); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient($to, $color-from, $color-to); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient($to, $color-from, $color-to); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to $to, $color-from, $color-to); /* Standard syntax */
}


@import 'settings';
@import 'foundation';
@import 'motion-ui';

$group-height: 35rem;
$inside-gutter: (
   small: 0.90625rem,
   medium: 1.8125rem,
);
$large-font-size: 1.25rem;
$content-font-size: 1rem;
$transparent-white: transparentize($white, 1);
$transparent-black: transparentize($black, 1);
$transparent-primary: transparentize($primary, 1);
$loading-indicator-width: 3rem;
$c-slider-button-width: 3rem;

@include foundation-everything(true);

.row {
  @include gutter(padding-left, 0);
  @include gutter(padding-right, 0);

  .row {
    @include gutter(margin-left, -.5);
    @include gutter(margin-right, -.5);
  }

  &--nested {
    @include gutter(padding-left, 0);
    @include gutter(padding-right, 0);

    .row {
      @include gutter(margin-left, 0);
      @include gutter(margin-right, 0);
    }
  }

  &--full-width {
    &,
    > .column {
      @include gutter(padding-left, 0);
      @include gutter(padding-right, 0);
    }
  }
}

// Fix flexbug, now unequal width distribution!
@each $key, $value in $grid-column-gutter {
  @include breakpoint($key) {
    .#{$key}-expand {
      flex-basis: auto;
    }
  }
}

.main__content-content > .grid:first-of-type > .row,
.main__sidebar-content > .grid:first-of-type > .row {
  // background-color: $white;

  &:first-of-type {
    @include gutter(padding-top, 1.5);

    > .column:first-of-type {
      .column-content > {
        .b-gallery-wrapper,
        .b-gallery,
        .b-hero,
        .b-campaign,
        .b-stations-teaser {
          @include gutter(margin-top, -1.5);
        }

        .b-campaign {
          @include gutter(padding-top, 0);
        }
      }
    }
  }
}

// Spacing
.spacing {
  $from: 0;
  $to: 3;

//  &-top-default {
//    @include gutter(padding-top, 1, $important: true);
//  }

  &-bottom-default.b-grid  {
    @include gutter(margin-bottom, 1, $important: true);
  }

  &-top {
    @for $i from $from through $to {
      &-#{$i}.spacing-top-#{$i} {
        @include gutter(margin-top, $i, $important: true);
      }
    }
  }

  &-bottom {
    @for $i from $from through $to {
      &-#{$i}.spacing-bottom-#{$i} {
        @include gutter(margin-bottom, $i, $important: true);
      }
    }
  }
}

// Color
.color {
  $colors: (
    primary: $primary,
    dark-primary: $dark-primary,
    gray-primary: $gray-primary,
    dark-gray-primary: $dark-gray-primary,
    secondary: $secondary,
    light-gray: $light-gray,
    medium-gray: $medium-gray,
    dark-gray: $dark-gray,
  );

  @each $name, $value in $colors {
    &-#{$name} {
      background-color: $value;

      @if lightness($value) < 50 {
        @extend .dark-background;
      }
    }
  }
}

// Inside gutter grid
.default--inside-gutter {
  .main__content-content > .grid > .row,
  .main__sidebar-content > .grid > .row {
    // background-color: $white;

    &:first-of-type {
      @include inside-gutter(padding, 0);

      > .column:first-of-type {
        .column-content > {
          .b-gallery-wrapper,
          .b-gallery,
          .b-hero,
          .b-campaign,
          .b-stations-teaser {
            // @include inside-gutter(margin, -1);
            @include inside-gutter(margin, 0);
          }

          .b-campaign {
            @include gutter(padding-top, 0);
          }
        }
      }
    }
  }

  .grid--outside-gutter {
    @include inside-gutter(padding, inside-gutter-value(small, true));
    max-width: $global-width;
    margin-left: auto;
    margin-right: auto;

    .row {
      @include inside-gutter(padding-left, 0);
      @include inside-gutter(padding-right, 0);
      max-width: inherit;
    }

    @include breakpoint(medium) {
      @include inside-gutter(padding-left, inside-gutter-value(medium));
      @include inside-gutter(padding-right, inside-gutter-value(medium));
    }
  }
}

.grid--inside-gutter {
  .row {
    @extend .row--nested;
  }

  .column {
    // @include inside-gutter(padding, 1);
    @include inside-gutter(padding, 0);
    position: relative;

    &:not(.row) {
      @include inside-gutter(margin, 0);
    }

  }

  // Spacing
  .spacing {
    $from: 0;
    $to: 3;

    &-top {
      @for $i from $from through $to {
        &-#{$i} .column-content > *:first-child {
          @include inside-gutter(padding-top, $i, $important: true);
        }
      }
    }

    &-bottom {
      @for $i from $from through $to {
        &-#{$i} .column-content > *:last-child {
          @include inside-gutter(padding-bottom, $i, $important: true);
        }
      }
    }
  }

  .column-content {
    &.stretch-height {
      position: absolute;
      width: 100%;

      &,
      .b-gallery-wrapper,
      .b-gallery,
      .vide,
      .iframe,
      .slick-slider {
        height: 100%;
      }
    }

    > {
      * {
        @include inside-gutter(padding-left, inside-gutter-value(small));
        @include inside-gutter(padding-right, inside-gutter-value(small));
      }

      *:first-child {
        @include inside-gutter(padding-top, inside-gutter-value(small));
      }

      *:last-child {
        @include inside-gutter(padding-bottom, inside-gutter-value(small));
      }

      .b-gallery-wrapper,
      .b-gallery-wrapper .b-gallery {
        @include inside-gutter(margin, 0);
        @include inside-gutter(padding, 0);
      }
    }
  }

  .b-gallery {
    .overlay-text {
      @include inside-gutter(padding-left, inside-gutter-value(small));
      @include inside-gutter(padding-right, inside-gutter-value(small));
      @include inside-gutter(padding-top, inside-gutter-value(small));
      @include inside-gutter(padding-bottom, inside-gutter-value(small));
    }
  }

  @include breakpoint(medium) {
    .c-slider {
      .slick {
        &-prev,
        &-next {
          @include inside-gutter(left, inside-gutter-value(medium));
        }
      }
    }
  }
}


.full-width {
  @include full-width;
}

.dark-background {
  @include dark-background;
}

@import 'debug';
@import 'flaticon';
@import 'animate';
@import 'slick.scss';
@import 'vendors/system-font/system-font';
@import 'vendors/dropdown/dropdown';
@import 'vendors/focuspoint/focuspoint';
@import 'components/header';
@import 'components/footer';
@import 'components/hours';
@import 'components/hours-update';
@import 'components/callouts';
@import 'components/campaigns';
@import 'components/form';
@import 'components/icon';
@import 'components/image-overlay';
@import 'components/languages';
@import 'components/nearBy';
@import 'components/pagination';
@import 'components/products';
@import 'components/sidebar';
@import 'components/slider';
@import 'components/sticker';
@import 'components/zellenkur';
@import 'blocks/button';
@import 'blocks/call-to-action';
@import 'blocks/campaigns';
@import 'blocks/column';
@import 'blocks/gallery';
@import 'blocks/hero';
@import 'blocks/html';
@import 'blocks/icon';
@import 'blocks/image';
@import 'blocks/jobs-teaser';
@import 'blocks/list';
@import 'blocks/navigation';
@import 'blocks/products';
@import 'blocks/sticker';
@import 'blocks/stores';
@import 'blocks/table';
@import 'blocks/tabs';
@import 'blocks/text';
@import 'templates/store';

div.cleaner {
	clear: both;
}

html {
  &.live-preview {
    .edit-button {
      display: none;
    }
  }
}

// Fix for iOS
.body {
  overflow-x: hidden;
  background-color: $white;
}

body {
  overflow-x: hidden;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.no-scroll {
    overflow: hidden;
  }

  &.is-loading {
    position: relative;

    @keyframes scale-out {
      0% {
        transform: translate(-50%, -100%) scale(0);
      }

      100% {
        transform: translate(-50%, -100%) scale(1);
        opacity: 0;
      }
    }

    .loading-indicator {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%) scale(0);
      z-index: 11111;
      width: $loading-indicator-width;
      height: $loading-indicator-width;
      background-color: transparentize($primary, .5);
      border-radius: 50%;
      animation-name: scale-out;
      animation-duration: 750ms;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}

.body {
  .main {
    animation-name: fadeIn;
    animation-duration: 375ms;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }

  &.is-exiting {
    .main {
      animation-name: fadeOut;
    }
  }
}

// ZellenkurAnimate
.zellenkur-animate {
  .c-header,
  .c-header-logo .logo-image,
  .c-header-logo .logo-text,
  .c-header-desktop .c-header-navigation__links > li > a,
  .c-header-desktop .c-header-navigation__links > li.more ul a,
  .c-header-stores-desktop-toggle,
  .main .b-text,
  .main .b-gallery,
  .main .b-navigation > ul > li,
  .main .b-image,
  .main .b-table,
  .main .b-icon .c-icon,
  .main .b-list .b-list-item,
  .main .button,
  .main .button-wrapper a,
  .main #address-hours-desktop,
  .main .t-store.store--map .b-column,
  .c-sticker,
  .c-zellenkur,
  .c-footer {
    visibility: hidden;

    &.animated-visible {
      visibility: visible;
    }
  }

  &:not(.first-init) {
    .c-header,
    .c-header-logo .logo-image,
    .c-header-logo .logo-text,
    .c-header-desktop .c-header-navigation__links > li > a,
    .c-header-desktop .c-header-navigation__links > li.more ul a,
    .c-header-stores-desktop-toggle {
      animation: none;
      visibility: visible;
    }
  }

  @include breakpoint(small only) {
    .main #address-hours-desktop,
    .main .t-store.store--map .b-column {
      animation: none;
      visibility: visible;
    }
  }
}

// ScrollReveal
.scrollreveal {
}

// Buttons
.button {
  @extend .button.primary;
  //hyphens: auto;
  @include breakpoint(large)
  {
	min-width: 13rem;
  }
  max-width: 100%;
  padding: (1.25 * $global-padding) (2 * $global-padding);
  font-size: 1.125rem;
  line-height: 1.25;
  // text-transform: uppercase;

  &.primary {
    border-color: transparent;
    background-color: $primary;
    color: $white;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: tint($primary, 10%);
      color: $white;
    }

    &:active {
      border-color: transparent;
      background-color: tint($primary, -10%);
      color: $white;
    }
  }

  &.secondary {
    border-color: transparent;
    background-color: $secondary;
    color: $white;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: tint($secondary, 10%);
      color: $white;
    }

    &:active {
      border-color: transparent;
      background-color: tint($secondary, -10%);
      color: $white;
    }
  }

  &.neutral {
    border-color: transparent;
    background-color: $medium-gray;
    color: $white;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: tint($medium-gray, 10%);
      color: $white;
    }

    &:active {
      border-color: transparent;
      background-color: tint($medium-gray, -10%);
      color: $white;
    }
  }

  &.success {
    border-color: transparent;
    background-color: $success;
    color: $white;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: tint($success, 10%);
      color: $white;
    }

    &:active {
      border-color: transparent;
      background-color: tint($success, -10%);
      color: $white;
    }
  }

  &.alert {
    border-color: transparent;
    background-color: $alert;
    color: $white;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: tint($alert, 10%);
      color: $white;
    }

    &:active {
      border-color: transparent;
      background-color: tint($alert, -10%);
      color: $white;
    }
  }

  // &.inverse {
  //   &.primary {
  //     border-color: $primary;
  //     background-color: transparent;
  //     color: $primary;

  //     &:hover,
  //     &:focus {
  //       border-color: tint($primary, 10%);
  //       background-color: tint($primary, 10%);
  //       color: $white;
  //     }

  //     &:active {
  //       border-color: tint($primary, 10%);
  //       background-color: tint($primary, -10%);
  //       color: $white;
  //     }
  //   }
  // }
}

.button-wrapper {
  * ~ & {
    margin-top: 2 * $global-margin;
  }

  a {
    @extend .button;
  }

  &.primary a {
    @extend .button.primary;
  }

  &.secondary a {
    @extend .button.secondary;
  }

  &.neutral a {
    @extend .button.neutral;
  }
}

a.button,
.button-wrapper a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.edit-button {
  @extend .button;
  @include fix-buttons;
  min-width: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: .75 * $global-padding;
  font-size: $small-font-size;
  z-index: 11;
  color: inherit;

  &,
  &:hover,
  &:focus,
  &:active {
    background-color: $white;
    border-color: $medium-gray;
    border-left: 0;
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    color: $primary;
  }

  &:active {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

.highlight {
  color: $primary;
}

a {
  @include transition;

  &[href^='tel:'] {
    color: inherit;
    text-decoration: inherit;
  }
}

h1, h2, h3, h4, h5, h6 {
	hyphens: none;
}

p {
  hyphens: auto;
}

h1, h2, h3, h4, h5, h6 {
  color: $dark-primary;

  &:last-child {
    //margin-bottom: 0;
  }

  + .subheading {
    margin-top: -.25rem;
  }
}

h2, h3, h4, h5, h6 {
  // text-transform: uppercase;
}


.subheading {
  font-size: 1.125rem;
  color: $dark-primary;

  @include breakpoint(medium) {
    font-size: 1.25rem;
  }
}

.large-text {
  font-size: $large-font-size;
}

.small-text {
  font-size: $small-font-size;
}

pre {
  margin-bottom: $global-margin;
}

// Debugging
// .row {
//   outline: 1px solid red;

//   .row {
//     outline-color: green;

//     .row {
//       outline-color: blue;
//     }
//   }
// }

table {
  td {
    border: .0625rem solid $light-gray;

    &.highlight {
      background-color: $medium-gray;
      color: inherit;
    }
  }

  caption {
    padding: (.5 * $global-padding) $global-padding;
    background-color: $primary;
    color: $white;
    text-align: left;
  }
}

[data-more-nav] {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  min-width: 0;
}

.dropdown .dropdownRightToLeft ul {
  left: auto;
  right: 0;

  ul {
    left: auto;
    right: 100%;
  }
}

.image-description {
  display: block;
}

.c-slider__item:only-child {
  .image-description {
    display: block;
    margin-top: .5 * $global-margin;
    text-align: center;
    p {
    	font-size: 1rem;
    	font-weight: $global-weight-semi-bold;
    }
  }
}

iframe {
  &.no-scroll {
    pointer-events: none;
  }
}

// TODO:
// Remove
.debugging {
  background-color: tint($warning, 75%);
  margin: $global-margin;
  padding: $global-padding;
  line-height: 1.25;

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.5rem;
  }

  .campaigns {
    margin-bottom: $global-margin;
    padding-bottom: $global-margin;
    border-bottom: 2px solid $black;

    .field {
      display: block;
    }
  }

  code {
    display: inline-block;
    margin-bottom: .25 * $global-margin;
    border: 0;
    background-color: transparent;
  }

  img {
    display: inline-block;
    width: 10rem;
  }

  .field {
    display: inline-block;
    vertical-align: top;
    margin-bottom: $global-margin;
    padding: .5 * $global-padding;
    border: 1px dotted $black;
    background-color: $white;
    max-width: 30rem;

    &--changed {
      border: 2px solid $secondary;
    }
  }

  .product,
  .variant {
    padding: $global-padding;
  }

  .product {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2 * $global-margin;
    background-color: tint($black, 80%);
  }

  .variant {
    background-color: tint($primary, 75%);
    border: 1px dotted tint($primary, 50%);
  }
}

////////////////////////////////////////////////////////

p.kicker {
	color: $primary;
	font-family: $font-family-secondary;
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0.375rem;
}

h1 {
	text-align: center;
	color: $black;
	text-transform: uppercase;
	line-height: 2.625rem;
	margin-bottom: 0.875rem;
}

.b-text:not(.b-text--center)
{
	h1, p{
		text-align: left;
	}
}

div.offers-your-store {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
	width: 20.8125rem;
	.offers-your-store-inner {
		width: inherit;
		p.title {
			text-transform: uppercase;
			color: $primary;
			font-size: 1.25rem;
			font-weight: bold;
		}
		ul {
			list-style: none;
			margin-left: 1.5rem;
			li {
				position: relative;
				margin-bottom: 0.75rem;
				&:before {
					position: absolute;
					left: -1.5rem;
					content: url(/foundation/dist/assets/img/location.svg);
				}
				a {
					font-size: 1rem;
					font-weight: bold;
					color: $black;
					&:hover {
						text-decoration: none;
					}
				}
				span {
					font-size: 1rem;
					font-weight: bold;
					color: $primary;
				}
			}
		}
	}
}

.iframe-google-map {
	padding-top: 0 !important;
}


.today-open-until {
	font-weight: bold;
	font-size: 1.5rem;
	color: $primary;
  margin-bottom: 0;
}

.bg-orange {
	background-color: #F1E2D2;
	margin: 0 -100rem;
	padding: 0 100rem;
}

.button-products {
	text-align: center;
	margin: 1rem 0 2rem 0;
}
.description p{
	text-align: center;
}

.background-gradient-linear {
	@include gradient-linear($gradient-color-from, $gradient-color-to, right);
}

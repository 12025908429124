.b-stores {
	.row .b-column {
    margin-bottom: 1rem;

    @include breakpoint(medium) {
		  margin-bottom: 3.75rem;
    }
		h2 {
			font-size: 1.5rem;
		}
		.address, .storehours {
			margin: 1.5rem 0 1rem;
			p {
				margin: 0;
				padding: 0;
				font-weight: bold;
				font-size: 0.875rem;
			}
		}
		.focuspoint {
			height: 200px;
			margin-bottom: 2rem;
		}
	}

  .c-hours {
    margin-bottom: 0;
    font-size: 0.875rem;
  }
}

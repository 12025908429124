.c-hours {
  @include gutter(margin-bottom);
  display: inline-block;
  max-width: 100%;

  &-store {
    margin-bottom: 1.5 * $global-margin;
  }

  a {
    color: inherit;
  }

  &-store-label {
    font-size: inherit;
    margin-bottom: 0;
    opacity: .5;
    color: inherit;
  }

  .day,
  .phone,
  .email {
    display: flex;
    flex-wrap: nowrap;
  }

  .day-label,
  .phone-label,
  .email-label {
    flex: 0;
    min-width: 4rem;
    margin-right: $global-margin;
  }

  .day-hours,
  .phone-content,
  .email-content {
    flex: 1;
  }

  &-contact {
    margin-top: .75 * $global-margin;
    padding-top: .75 * $global-padding;
    border-top: 1px solid $black;
  }
}

div.b-product div.b-product-content div.c-product {
	// Filter
	.filter {
		//text-align: center;
		//margin-bottom: 1rem;
		> ul {
			list-style-type: none;
			> li {
				position: relative;
				float: left;				
			 
				> ul.dropdown {
					border-radius: 0.5rem;
					z-index: 1001;
					top: 100%;
					position: absolute;
					margin-top: 0px;
					margin-left: 1.0625rem;
					left: 0;
					display: none;
					min-width: 10rem;
					text-align: left;
					list-style: none;
					background-color: $medium-gray;
					
					> li {
						width: 100%;
						height: 100%;
						&:not(:first-child)
						{
							border-top: 1px solid #505050;
						}
						&:first-child a {
							border-radius: 0.5rem 0.5rem 0 0;
						}
						&:last-child a {
							border-radius: 0 0 0.5rem 0.5rem;
						}
						a {
							padding: 0.5rem 1rem;
							color: $white;
							&:hover, &:focus {
								color: $black;
								background-color: $white;
							}									
						}							
					}
				}
			 
				button.filter {
					font-size: 1rem;
					font-weight: $global-weight-semi-bold;
					color: $gray-primary;
					background-color: $white;
					margin: 0 1.125rem;
					padding: 0 1rem;
					cursor:pointer;	// Regel für disabled-Buttons (Unterkategorien)
					&:hover, &.is-checked {
						background-color: $primary;
						color: $white;
					}
				}
			}
			> li:hover > ul.dropdown {
				display: block;
			}
		}	
	}
	
	.product { 
		.image {
			position: relative;
		}	
	}
	.price-box {
		// small: price-box drunter
		position: relative;
		width: 100%;
		min-height: 95px;
		
		padding: 0.5rem 1.5rem;
		color: $white;
		background-color: transparentize($primary, 0.3);	

		@include breakpoint(medium) {
			// ab medium: price-box über Bild
			width: 140px;
			//height: 95px;
			padding: 0.5rem 1rem 0.5rem 1.5rem;	
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.unit p{
			font-weight: bold;
			font-size: 1rem;
			margin: 0;
		}
		.uvp {
			font-weight: bold;
			font-size: 1rem;
			.uvp-text {
			}
			.uvp-price {
				text-decoration: line-through;
			}
		}
		.price p{
			font-family: $font-family-secondary;
			font-size: 1.625rem;
			font-weight: bold;
			margin: 0;
			line-height: 32px;
		}
	}
	.product-title p{
		text-align: center;
		font-weight: $global-weight-semi-bold;
		font-size: 1rem;
		margin-top: 0.625rem;
	}
}

#storeListNotLocalized .offers-your-store-inner {
	text-align: center;
	.link {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

// "Angebote ihrer Filiale zeigen" nicht anzeigen, wird durch JS nach lokalisierung angezeigt
#storeListLocalized {
	display: none;
}

// Button "alle Produkte anzeigen"
div.b-product div.b-product-content div.c-product .button-show-all-products {
	display: block;
	padding-bottom: 1.25rem;
	.b-button {
		margin-bottom: 0;
	}
}

// Button "alle Produkte anzeigen" auf der Startseite nicht anzeigen
div.e-homepage div.b-product div.b-product-content div.c-product .button-show-all-products {
	display: none;
}

div.b-product div.b-product-content div.c-product.bg-orange {
	padding-top: 2.5rem;
}
$b-hero-outside: $medium-gray;
$b-hero-inside: $light-gray;

.b-hero {
  @include full-width;
  @include gutter(padding-top, 3);
  @include gutter(padding-bottom, 3);
  background-color: $b-hero-inside;
  background-image: linear-gradient(
    $b-hero-outside,
    $b-hero-inside 33%,
    $b-hero-inside 66%,
    $b-hero-outside
  );

  &__main {
    position: relative;
  }

  @include breakpoint(small only) {
    .c-sticker {
      position: static;
      display: block;
    }
  }
}

$c-footer: 'c-footer';
$c-footer-bioland-breakpoint: 67rem;

.c-footer {
  margin-bottom: 2 * $global-margin;

  &__first {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: (2.5 * $global-padding) $global-padding;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    position: relative;
  }

  &__first-bottom {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
  }

  &__second {
    position: relative;
  }

  &-social {
    display: block;
    text-align: center;
    color: inherit;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &:hover,
    &:focus {
      color: inherit;
    }

    // &-text {
    //   display: none;
    // }

    img {
      display: inline-block;
      margin-bottom: .5rem;
    }

    // @include breakpoint(400px) {
    //   &-text {
    //     display: block;
    //   }
    // }

    @include breakpoint($c-footer-bioland-breakpoint) {
      position: absolute;
      bottom: 3.125 * $global-margin;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-fb {
    @include breakpoint($c-footer-bioland-breakpoint) {
      left: 0;
    }
  }

  &-insta {
    @include breakpoint($c-footer-bioland-breakpoint) {
      left: 3.75rem;
    }
  }

  &-bio-kontrollnr {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
    @include breakpoint($c-footer-bioland-breakpoint) {
      position: absolute;
      bottom: 5.4375 * $global-margin;
      right: 7.75rem;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .bioland {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    // margin-left: -30px;

    @include breakpoint($c-footer-bioland-breakpoint) {
      position: absolute;
      bottom: 3.5 * $global-margin;
      right: 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .c-zellenkur {
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: .5 * $global-margin;

    &:hover,
    &:focus {
      .c-zellenkur__svg {
        fill: $primary;
      }
    }

    &__svg {
      fill: $light-gray;
    }
  }

  &__logo,
  &__links {
    display: block;
  }

  &__logo {
    margin-bottom: $global-margin;

    img {
      display: block;
    }
  }

  &__links {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 2 * $global-margin;

    @include breakpoint($c-footer-bioland-breakpoint) {
      margin-bottom: .5 * $global-margin;
    }

    a {
      color: inherit;
      display: inline-block;
      padding: .125rem .25rem;
    }

    li {
      margin-right: .5 * $global-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.b-navigation {
  @include gutter(margin-bottom);
  font-size: $content-font-size;

  ul {
    list-style-type: none;
    margin: 0;

    ul {
      margin-left: $global-margin;
    }
  }

  a {
    color: inherit;
    display: block;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &--horizontal {
    li,
    a {
      display: inline-block;
    }

    li {
      margin-right: .5 * $global-margin;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.focuspoint {
  position: relative;
  overflow: hidden;
}

.focuspoint img {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto; height: auto;
  min-width: 100%; min-height: 100%;
  max-height: none; max-width: none;
}
